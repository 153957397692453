import React, { Component } from 'react';
import { connect } from "react-redux";
import { Route, Redirect, Switch, BrowserRouter, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import LayoutController from './LayoutController';
import ApplicationTaskPanel from "./ApplicationTaskPanel";
import DisplayWebPageNew from '../Panels/PanelComponents/DisplayWebPageNew';
import WebPageDisplay from '../Panels/PanelComponents/WebPageDisplay';
import ScreenRecorder from '../Panels/PanelComponents/ScreenRecorder';
import CameraRecorder from '../Panels/PanelComponents/CameraRecorder';
import { isEmpty } from 'lodash';

class CertificateIndex extends Component {

// const CertificateIndex = () => {

    render() {

        // const history = useHistory();
        // const location = useLocation();
        // const path = useRouteMatch();

        // console.log(path);

        // return (

        //     <BrowserRouter>
        //         <Switch>
        //         <Route exact path={[
        //             '/MyTasks', 
        //             '/MyApplications',
        //             '/TeamTasks',                    
        //             '/TeamApplications' 

        //         ]} 
        //             render={(props) => 
        //                 <LayoutController {...props} />
        //             }
        //         />
        //         <Route exact path={[ 
        //             '/MyTasks/:certificate', 
        //             '/MyApplications/:certificate',
        //             '/TeamTasks/:certificate',                    
        //             '/TeamApplications/:certificate'
        //         ]}
        //             render={(props) =>
        //                 <ApplicationTaskPanel {...props} />
        //             } 
        //         />

        //         <Route exact path= 
        //             {`/MyTasks/:certificate/MediaBrowser`} 
        //             render={(props) => (
        //                 <DisplayWebPageNew {...props} />
        //             )} 
        //         />

        //         {/* If no path can be found redirect to my tasks */}
        //         <Redirect to='/MyTasks' />
        //         </Switch>
        //     </BrowserRouter>

        // );


        return (

            <Switch>
                <Route exact path={[
                    '/MyTasks', 
                    '/MyApplications',
                    '/TeamTasks',                    
                    '/TeamApplications' 

                ]} 
                    render={(props) => 
                        <LayoutController {...props} />
                    }
                />
                <Route exact path={[ 
                    '/MyTasks/:certificate', 
                    '/MyApplications/:certificate',
                    '/TeamTasks/:certificate',                    
                    '/TeamApplications/:certificate'
                ]}
                    render={(props) => (
                        (isEmpty(this.props.selectedApplicationTask)) ? (
                            <Redirect to={{ pathname: props.location.pathname }} />
                        ) : (
                            <ApplicationTaskPanel {...props} />
                        )
                    )} 
                />

                <Route path= 
                    {`/MyTasks/:certificate/MediaBrowser`} 
                    render={(props) => (
                        <WebPageDisplay {...props} />
                    )} 
                />

                <Route path= 
                    {`/MyTasks/:certificate/MediaRecorder`} 
                    render={(props) => (
                        <ScreenRecorder {...props} />
                    )} 
                />

                <Route path= 
                    {`/MyTasks/:certificate/Camera`} 
                    render={(props) => (
                        <CameraRecorder {...props} />
                    )} 
                />

                {/* If no path can be found redirect to my tasks */}
                <Redirect to='/TeamTasks' />
            </Switch>

        );
    }

}

// export default CertificateIndex;

const mapStateToProps = (state) => {

    const { selectedApplicationTask } = state.applications;

    return { selectedApplicationTask };
};

export default connect(mapStateToProps)(CertificateIndex);