import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import fetch from 'isomorphic-fetch';
import config from '../../../config';
import Loader from '../../Loader/Loader';

import { apiParse } from '../../../utils/Common';

const env = process.env.NODE_ENV || 'development';

const ScreenRecorder = () => {
    const [ recording, setRecording ] = useState(false);
    const [ videoUrl, setVideoUrl ] = useState('');
    const [ recordedBlob, setRecordedBlob ] = useState(null);
    const [ message, setMessage ] = useState('');
    const [ stream, setStream ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const videoRef = useRef(null);
    const recorderRef = useRef(null);
    const timeoutRef = useRef(null);

    const maxRecordingTime = 120000; // 2 minutes in milliseconds

    const { sessionId } = useSelector(state => state.session);
    const { selectedApplicationTask } = useSelector(state => state.applications);
    const { ApplicationTasks } = useSelector(state => state.applications.applications);


    const handleStopRecording = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        if (recorderRef.current && recorderRef.current.state !== 'inactive') {
            recorderRef.current.stop();
        }
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
        }
        setStream(null);
    }, [stream]);


    useEffect(() => {
        if (recording && videoRef.current && stream) {
            videoRef.current.srcObject = stream;
            videoRef.current.muted = true;
            videoRef.current.play()
                .catch((err) => {
                    console.error('Error playing video stream:', err);
                });

            // Set a timeout to stop recording after maxRecordingTime
            timeoutRef.current = setTimeout(handleStopRecording, maxRecordingTime);
        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [ recording, stream, handleStopRecording ]);


    const handleStartRecording = async () => {
        try {
            const displayStream = await navigator.mediaDevices.getDisplayMedia({ video: true });
            const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });

            const combinedStream = new MediaStream([
                ...displayStream.getVideoTracks(),
                ...audioStream.getAudioTracks()
            ]);

            setStream(combinedStream);

            recorderRef.current = new MediaRecorder(combinedStream, { mimeType: 'video/mp4' });

            const chunks = [];
            recorderRef.current.ondataavailable = event => {
                if (event.data.size > 0) {
                    chunks.push(event.data);
                }
            };

            recorderRef.current.onstop = () => {
                const webmBlob = new Blob(chunks, { type: 'video/mp4' });
                const url = URL.createObjectURL(webmBlob);
                setVideoUrl(url);
                setRecordedBlob(webmBlob);
                if (videoRef.current) {
                    videoRef.current.srcObject = null;
                    videoRef.current.src = url;
                    videoRef.current.muted = false;
                    videoRef.current.play()
                        .catch((err) => {
                            console.error('Error playing recorded video:', err);
                        });
                }
                setRecording(false);
            };

            recorderRef.current.start();
            setRecording(true);
        } catch (err) {
            console.error('Error accessing media devices.', err);
        }
    };


    const handleDeleteRecording = () => {
        setVideoUrl('');
        setRecordedBlob(null);
        setMessage('');
        if (videoRef.current) {
            videoRef.current.srcObject = null;
            videoRef.current.src = '';
        }
        setRecording(false);
    };


    const handleUpload = async () => {
        if (!recordedBlob) {
            return;
        }
        setLoading(true);
        const URI = `${config[env].URL}/upload-video`;
        const applicationGUID = ApplicationTasks?.[selectedApplicationTask]?.applicationGUID;

        console.log('applicationGUID;', applicationGUID);
        console.log('sessionId;', sessionId);
        console.log('appkey;', process.env.REACT_APP_APP_KEY);

        const formData = new FormData();
        formData.append('video', recordedBlob, 'recording.mp4');
        formData.append('applicationGUID', applicationGUID);

        try {
            const postVideo = async () => {
                const response = await fetch(URI, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        'appkey': process.env.REACT_APP_APP_KEY,
                        'sessionid': sessionId
                    }
                });
                const jsonResult = await response.json();
                return jsonResult;
            };

            let result = await apiParse(await postVideo());

            setLoading(false);
            setMessage(result.message);
            alert(result.message);

            handleDeleteRecording();
        } catch (err) {
            setLoading(false);
            console.error('Error uploading video:', err);
            setMessage(`Error uploading video: ${err.message}`);
            alert(err.message);
        }
    };


    const formatTime = (milliseconds) => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `(Maximum recording time ${minutes} minutes${seconds ? `, ${seconds} seconds.` : `.`})`;
    };

    
    return (
        <div>
            <h1>Screen Recorder</h1>
            <div style={{ margin: '5px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                <button 
                    className="button" 
                    onClick={recording ? handleStopRecording: handleStartRecording} 
                    disabled={!recording && !!videoUrl}
                >
                        {recording ? 'Stop Recording' : 'Start Recording'}
                </button>
                {/*<button onClick={handleDeleteRecording} disabled={!videoUrl}>Delete Recording</button>*/}
                {videoUrl && (
                    <>
                        <button className="button" onClick={handleDeleteRecording}>
                            Delete Recording
                        </button>
                        <a href={videoUrl} download={`recording-${Date.now()}.mp4`} style={buttonStyle}>
                            Download Recording
                        </a>
                        <button className="button" onClick={handleUpload}>
                            Upload Recording
                        </button>
                    </>
                )}
            </div>
            {message && (
                <div>
                    <h2>Message</h2>
                    <p>{message}</p>
                </div>
            )}
            {recording && (
                <div>
                     <h2>Live Preview <small><i>{formatTime(maxRecordingTime)}</i></small></h2>
                    <video 
                        ref={videoRef} 
                        autoPlay 
                        muted controls
                        style={videoStyle}
                    />
                </div>
            )}
            {videoUrl && !recording && (
                <div>
                    <h2>Recorded Video</h2>
                    <video 
                        ref={videoRef} 
                        src={videoUrl} 
                        autoPlay 
                        controls 
                        style={videoStyle}
                    />
                </div>
            )}
            
            <Loader
                className="loader__spinner"
                active={loading}
                activeName="loader--active"
                loaderName="loader"
                spinnerName="loader__spinner"
			/>
        </div>
    );
};

const buttonStyle = {
    display: 'inline-block',
    padding: '8px 16px',
    margin: '0 10px',
    fontSize: '1.8rem',
    fontWeight: 'bold',
    textAlign: 'center',
    textDecoration: 'none',
    color: '#fff',
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer'
};

const videoStyle = {
    "width": "50%", 
    // "height": "50%",  
    "margin": "10px",
    "border": "2px solid #000",
    "borderRadius": "8px",
    "boxShadow": "0 0 10px rgba(0, 0, 0, 0.5)"
};

export default ScreenRecorder;