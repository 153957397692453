import React from 'react';
import { connect } from "react-redux";
import { Draggable } from "react-beautiful-dnd";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import { updateSelectedApplicationTask } from "../../actions";
import { AllowableActionGUID } from "../../utils/Constants";

function ApplicationTask(props) {
    
    const selectViewGUID = () => {

        const {
            id
        } = props.task;

        props.updateSelectedApplicationTask(id);
      };

    const launchTask = (openAsModel, taskName, id, segmentAddress) => {

        if(openAsModel) {

            const { applicationTaskCallback } = props;

            return(
                <p className="action large_indent cursor" onClick={() => applicationTaskCallback(id)}>{taskName}</p>
            );

        } else {
            const { viewGUID, spotliteAddress, applicationGUID, segmentAddress } = props.task;
        
            var addressParts = spotliteAddress.split('-');
            
            let address = "";
            
            if(addressParts.length > 1){
                address = addressParts[addressParts.length -1];
            } else {
                address = addressParts[0];
            }
            
            //let certificateAddress = (spotliteAddress || 'address.not.found').split('.').slice(1).join('.');
            return(
                <Link to={
                        {
                            pathname: `${props.bookmark.bookmarkPath}/${applicationGUID}`, 
                            segmentSpotliteAddress: segmentAddress,
                            state:{
                                viewGUID, 
                                applicationGUID,
                                spotliteAddress: address,
                                pathname: `${props.bookmark.bookmarkPath}/${applicationGUID}`
                            }
                        }
                    } 
                >
                        <p className="action large_indent">{taskName}</p>
                </Link> 
            );     
        }
    }; 

    const { 
        id,
        //spotliteAddress,
        taskName,
        //segmentGUID,
        //applicationGUID,
        applicationInstanceName,
        serviceName,
        //viewGUID,
        openAsModal,
        allowableActionGUID,
        //taskCreateDataAndTime,
        taskDueDateTime,
        overallStatusRAG,
        overallStatusRAGHash,
        //overallStatusGUID,
        thumbnail,
        //viewSeq
    } = props.task;

    const { segmentAddress } = props;

    // let dateDueStatusColor = "#FFFFFF";
    let dateDueStatusColor = "";
    let overallStatusColor =`${overallStatusRAG}`;

    let colorObj = props.applicationColor;

    let key = Object.keys(colorObj).find(key => key === id);
    //console.log(colorObj, overallStatusRAG);
    
   
    
    //if overallStatusRAG is Green, Amber, Red then dateDueStatusColor is clear
    if(overallStatusRAG !== 'Clear') {
        dateDueStatusColor = '#FFFFFF';
        overallStatusColor = overallStatusRAGHash;
    } else {
        let today = new Date(Date.now()).toISOString();
    
        if (taskDueDateTime < today) {
            //console.log(`${id} is overdue.`);
            dateDueStatusColor = '#FF6565'
        } else {
            dateDueStatusColor = '#FFFFFF';
        }
        overallStatusColor = '#FFFFFF';
    }


    /*
    if(typeof key !== 'undefined'){
        dateDueStatusColor = colorObj[key].taskDueDateColorHash;
        overallStatusColor = colorObj[key].overallStatusRAGHash;

        console.log(colorObj);
    }
    */

    let isDragDisabled = false;

    // if(allowableActionGUID === AllowableActionGUID) {
    //     isDragDisabled = true;
    // }


    
    let formatDate = new Date(taskDueDateTime),
    day = '' + formatDate.getDate(),
    year = formatDate.getFullYear();

    const monthNames = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.",
    "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."
    ];

    let date = [day,  monthNames[formatDate.getMonth()], year].join(' ');
    
    
    const getTaskStyle = (isDragging, draggableStyle) => ({
        // change background colour if dragging
        background: isDragging ? "lightgreen" : "#fff",
        ...draggableStyle
    });

    /*
    dateDueStatusColor = "red";

    const getDueDateStatusColor = (isDragging) => {
        return (isDragging ? "lightgreen" : dateDueStatusColor);
    };

    const getOverallStatusColor = (isDragging) => {
        return (isDragging ? "lightgreen" : overallStatusColor);
    }*/

    return (
        <Draggable draggableId={id} index={props.index} isDragDisabled={isDragDisabled}>
            {(provided, snapshot) => 
            
            
            <div className="application_task_box"
                onClick={selectViewGUID}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getTaskStyle(snapshot.isDragging, provided.draggableProps.style)}
                >
                
                <div className="application_task_header">
                        <Avatar size="25" round={true} name={applicationInstanceName} src={thumbnail}/>
                        <p className="indent">{applicationInstanceName}</p>
                </div>

                <div className="application_task_divider"/>

                <div className="application_task_content">

                    <div className="status" style={{"--status-color": `${overallStatusColor}`}} >
                        <p className="indent">{serviceName}</p>
                    </div>
    
                    {launchTask(openAsModal, taskName, id, segmentAddress)}   
                
                    <div className="status" style={{"--status-color": `${dateDueStatusColor}`}} >
                        <p className="indent">{date}</p>
                    </div>

                </div>
                
            </div>

            
            }
        </Draggable>
    ); 
    

}

const mapStateToProps = (state) => {

    const { applicationColor } = state.applications;
    const bookmark = state.bookmark

    return { applicationColor, bookmark };
};

export default connect(mapStateToProps, {
    updateSelectedApplicationTask,
})(ApplicationTask);