import React, { Component } from 'react';
import { connect } from 'react-redux';
import { instanceOf } from 'prop-types';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import CertificatesIndex from './LayoutController/CertificatesIndex';
import App404 from './App404/App404';
import LeftMenu from './LeftMenu/LeftMenu';
import Header from './Header/Header';
import Team from './Team/Team';
import Accounts from './Accounts/Accounts';
import { withCookies, Cookies } from 'react-cookie';
import Transactions from './Transactions/Transactions';
import Login from './Login/Login';
import BusinessSearch from './BusinessSearch/BusinessSearch';
// import DisplayWebPage from './Panels/PanelComponents/DisplayWebPage';
import DisplayWebPageNew from './Panels/PanelComponents/DisplayWebPageNew';
import WebPageDisplay from './Panels/PanelComponents/WebPageDisplay';
import ScreenRecorder from './Panels/PanelComponents/ScreenRecorder';
import CameraRecorder from './Panels/PanelComponents/CameraRecorder';
import MobileScreen from './Mobile/MobileScreen';

import {
  sessionSet,
  logout,
  fetchThemeData,
  portalSet
} from "./../actions";
import { isEmpty, repeat } from 'lodash';
import { errorHandler } from '../utils/errorHandler';

import { MsalProvider } from "@azure/msal-react";
import MaintainSettings from './MaintainSettings/MaintainSettings';

class App extends Component {

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    // console.log(props)
    this.handleLogout = this.handleLogout.bind(this);

    // console.log(window.location)

    this.state = {
      menuVisible: false,
      isLoading: true,
      sourceUrl: window.location.hostname,
      displayConfig: {}
    };

    const { cookies } = this.props;

    //set redux session based on cookie
    if (cookies.get('sessionId')) {
      this.onSetSession(cookies.get('sessionId'));
    }
  }

  getDomain(url) {
    // let hostName = this.getHostName(url);
    let hostName = url;
    let domain = hostName;

    if (hostName != null) {
      // remove the first element in the url as that is the route to either the client or applicant portal
      let parts = hostName.split('.');
      if (parts.length > 1) {
        parts.shift()
        domain = parts.join('.')
      }
    }

    return domain;
  }

  getFaviconEl = () => {
    return document.getElementById("favicon");
  }

  componentDidMount() {

    // Get the information required to brand the screens
    this.getBrandingInformation()


    this.setState({ menuVisible: false });
  }

  getBrandingInformation = async () => {
    const { sourceUrl } = this.state
    const { fetchThemeData } = this.props

    // console.log(sourceUrl)

    let clientdomain = this.getDomain(sourceUrl)
    let brandDetails = {}

    // console.log(clientdomain)

    try {   // retrieve branding information

      const response = await fetchThemeData(clientdomain)
      let themeDataObject = {}
      if (response.themeData) {
        themeDataObject = JSON.parse(response.themeData.ThemeConfig)

        const favicon = this.getFaviconEl()
        // Set Portal Type Agent or Applicant
        if (themeDataObject) {
          // Extract the prefix of the URL and match it against the themeData information
          let urlPrefix = sourceUrl ? sourceUrl.split(".")[0] : null

          let portalType = ""
          let portalDisplay = ""

          if (urlPrefix === themeDataObject.portal_prefixes.applicant) {
            portalType = "applicant"
            portalDisplay = themeDataObject.portal_prefixes.applicant_portal_displayname
            document.title = themeDataObject.portal_prefixes.applicant_tab_value
            favicon.href = `/assets/img/favicons/${themeDataObject.portal_prefixes.client}/${themeDataObject.portal_prefixes.agent_icon}`;
          } else if (urlPrefix === themeDataObject.portal_prefixes.agent) {
            portalType = "agent"
            portalDisplay = themeDataObject.portal_prefixes.agent_portal_displayname
            document.title = themeDataObject.portal_prefixes.agent_tab_value
            favicon.href = `/assets/img/favicons/${themeDataObject.portal_prefixes.client}/${themeDataObject.portal_prefixes.agent_icon}`;
          } else {
            // PMD 23/12/21 Used to switch between portalType when running locally
            // Also used when value not set in database defaults to Agent
            // TODO: Switch to test agent or applicant
            portalType = "agent"
            portalDisplay = "Localhost Agent Portal"
            // portalType = "applicant"
            // portalDisplay = "Localhost Applicant Portal"
            // document.title = "CDD Testing Slaters System"
            // document.title = themeDataObject.portal_prefixes.applicant_tab_value
            document.title = themeDataObject.portal_prefixes.agent_tab_value
            favicon.href = `/assets/img/favicons/${themeDataObject.portal_prefixes.client}/${themeDataObject.portal_prefixes.agent_icon}`;
          }

          let logoImage, logoWidth, logoHeight, loginLeft;
          if (themeDataObject.company_logo && themeDataObject.company_logo.logo) {
            logoImage = `/assets/img/${themeDataObject.company_logo.logo}`
            logoWidth = themeDataObject.company_logo.logoWidth
            logoHeight = themeDataObject.company_logo.logoHeight
            loginLeft = `/assets/img/${themeDataObject.company_logo.loginLeft}`
          }

          this.onSetPortal({ portalType: portalType, portalDisplayName: portalDisplay, logo: logoImage, logoWidth: logoWidth, logoHeight: logoHeight, loginLeft: loginLeft })
        }

        // Apply colour branding
        if (themeDataObject && themeDataObject.brand_colours) {
          let colourScheme = themeDataObject.brand_colours
          // console.log(colourScheme)
          Object.keys(colourScheme).forEach(item => {
            document.documentElement?.style.setProperty(`--${item}`, colourScheme[item]);
          });
        }

        // Apply background
        if (themeDataObject && themeDataObject.company_logo) {
          const { company_logo } = themeDataObject
          const { backgroundImage, backgroundRepeat, backgroundSize, backgroundPosition } = company_logo
          let companyLogo = `url(/assets/img/${backgroundImage})`

          const styles = {
            backgroundImage: companyLogo,
            backgroundRepeat: backgroundRepeat,
            backgroundSize: backgroundSize,
            backgroundPosition: backgroundPosition,
          }

          // Apply styles
          Object.assign(document.body.style, styles)

        }

        brandDetails = response.themeData
      } else {
        // No record found so use default branding
        console.log(response.msg)
        throw new Error(response.msg);
      }
    } catch (error) {
      // TODO: Needs error handling
      console.log(error)
      // PMD 20/05/22 Error handling
      errorHandler(error)
      // throw error;
    }
    this.setState({ displayConfig: brandDetails, isLoading: false });
  }

  onSetSession(sessionId) {
    this.props.sessionSet(sessionId);
  }

  onSetPortal(portalDetails) {
    this.props.portalSet(portalDetails);
  }

  onLogout() {
    this.props.logout();
  }

  handleSidebar = () => {
    this.setState(prevState => ({
      menuVisible: !prevState.menuVisible,
    }));
  }

  handleLogout = (event) => {
    const { cookies } = this.props;

    this.setState({ menuVisible: false, });

    if (event) {
      event.preventDefault();
    }

    cookies.remove('sessionId', { path: '/' });
    if (this.props.instance.getAllAccounts().length > 0) {
      this.props.instance.logoutPopup({ postLogoutRedirectUri: "/", mainWindowRedirectUri: "/" })
    }
    this.props.logout();

  }

  renderLeftMenu() {
    if (this.props.sessionId) {
      return (
        <LeftMenu open={this.state.menuVisible} />
      );
    }
  }

  renderSideBar() {

    if (this.props.dataConfig) {

      if (this.state.menuVisible) {
        if (document.getElementById("mySideBar") && document.getElementById("root")) {
          document.getElementById("mySideBar").style.width = "90px";
          document.getElementById("root").style.marginLeft = "90px";
        } else {
          //console.log("Alert - element not found");
        }
      } else {
        if (document.getElementById("mySideBar") && document.getElementById("root")) {
          document.getElementById("mySideBar").style.width = "0";
          document.getElementById("root").style.marginLeft = "0";
        } else {
          //console.log("Alert - element not found");
        }
      }

      return (
        <div id="mySideBar" className="collapsablesidebar">
          {this.renderLeftMenu()}
        </div>
      );
    }

  }

  renderAvatarMenu() {
    if (this.props.sessionId && this.props.dataConfig && !isEmpty(this.props.dataConfig)) {
      return (
        <Header onLogout={this.handleLogout} onHandleSidebar={this.handleSidebar} />
      );
    }
  }

  renderSwitch() {

    return (
      <Switch>

        <Route exact path="/" render={(props) => (
          (this.props.sessionId && this.props.dataConfig) ? (
            <CertificatesIndex {...props} />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: this.props.location } }} />
          )
        )} />

        <Route path="/mobile-screen" render={(props) => (
          <MobileScreen {...props} />
        )} />

        <Route path={[
          '/MyTasks',
          '/MyApplications',
          '/TeamTasks',
          '/TeamApplications'
        ]}
          render={(props) => (
            (this.props.sessionId && this.props.dataConfig) ? (
              <CertificatesIndex {...props} />
            ) : (
              <Redirect to={{ pathname: "/login", state: { from: this.props.location } }} />
            )
          )}
        />

        <Route path="/team" render={(props) => (
          (this.props.sessionId && this.props.dataConfig) ? (
            <Team {...props} />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: this.props.location } }} />
          )

        )} />

        <Route path="/account" render={(props) => (
          (this.props.sessionId && this.props.dataConfig) ? (
            <Accounts {...props} />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: this.props.location } }} />
          )
        )} />

        <Route path="/activity" render={(props) => (
          (this.props.sessionId && this.props.dataConfig) ? (
            <Transactions {...props} />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: this.props.location } }} />
          )
        )} />

        {/* PMD 21/12/22 Add additional routes */}
        <Route path={[
          '/BusinessSettings',
          '/TeamSettings',
          '/AgentSettings'
        ]}
          render={(props) => (
            (this.props.sessionId && this.props.dataConfig) ? (
              <MaintainSettings {...props} />
              // <Transactions {...props} />
            ) : (
              <Redirect to={{ pathname: "/login", state: { from: this.props.location } }} />
            )
          )} />

        <Route path="/search" render={(props) => (
          (this.props.sessionId && this.props.dataConfig) ? (

            <BusinessSearch {...props} />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: this.props.location } }} />
          )
        )} />

        <Route path="/MediaBrowser" render={(props) => (
          (this.props.sessionId && this.props.dataConfig) ? (
            <WebPageDisplay {...props} />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: this.props.location } }} />
          )
        )} />

        <Route path="/MediaRecorder" render={(props) => (
          (this.props.sessionId && this.props.dataConfig) ? (
            <ScreenRecorder {...props} />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: this.props.location } }} />
          )
        )} />

        <Route path="/Camera" render={(props) => (
          (this.props.sessionId && this.props.dataConfig) ? (
            <CameraRecorder {...props} />
          ) : (
            <Redirect to={{ pathname: "/login", state: { from: this.props.location } }} />
          )
        )} />

        <Route path="/refresh/*" component={null} />

        <Route path='/login' component={Login} />

        <Route component={App404} />

      </Switch>
    );
  }

  render() {
    const { isLoading } = this.state

    if (isLoading) return null

    const portalType = this.props.portalDetails.portalType

    return (
      // PMD 05/08/22 B2C Implementation
      <MsalProvider instance={this.props.instance} >
        <div id="main_layer_id" className="main_layer">
          {/* {portalType === 'applicant' && this.applicantIcon()} */}
          {this.renderSideBar()}

          <div id="content" className="main_content_layer">

            {this.renderAvatarMenu()}
            {this.renderSwitch()}

          </div>
        </div>
      </MsalProvider>
    );
  }
}

const mapStateToProps = (state) => {
  const { sessionId, dataConfig, portalDetails } = state.session;
  return { sessionId, dataConfig, portalDetails };
};

export default withRouter(connect(mapStateToProps, {
  sessionSet,
  logout,
  fetchThemeData,
  portalSet,
})(withCookies(App)));

