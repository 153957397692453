import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import fetch from 'isomorphic-fetch';
import config from '../../../config';
import Loader from '../../Loader/Loader';

import { apiParse } from '../../../utils/Common';

const env = process.env.NODE_ENV || 'development';

const CameraRecorder = () => {
    const [recording, setRecording] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [recordedBlob, setRecordedBlob] = useState(null);
    const [message, setMessage] = useState('');
    const [stream, setStream] = useState(null);
    const [loading, setLoading] = useState(false);
    const videoRef = useRef(null);
    const recorderRef = useRef(null);
    const timeoutRef = useRef(null);

    const maxRecordingTime = 120000; // 2 minutes in milliseconds

    const { sessionId } = useSelector(state => state.session);
    const { selectedApplicationTask } = useSelector(state => state.applications);
    const { ApplicationTasks } = useSelector(state => state.applications.applications);

    const handleStopRecording = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        if (recorderRef.current) {
            recorderRef.current.stop();
        }
        if (stream) {
            stream.getTracks().forEach(track => track.stop()); // Stop all tracks to release the camera and microphone
        }
        setStream(null);
    }, [stream]);

    useEffect(() => {
        if (recording && videoRef.current && stream) {
            videoRef.current.srcObject = stream;
            videoRef.current.muted = true;
            videoRef.current.play()
                .catch((err) => {
                    console.error('Error playing video stream:', err);
                });

            timeoutRef.current = setTimeout(handleStopRecording, maxRecordingTime);
        }

        return () => {
            // Ensure camera and microphone are turned off on component unmount
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
            }
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [recording, stream, handleStopRecording]);

    const handleStartRecording = async () => {
        try {
            const userStream = await navigator.mediaDevices.getUserMedia({
                video: { frameRate: 30 },
                audio: { sampleRate: 44100 }
            });

            setStream(userStream);

            recorderRef.current = new MediaRecorder(userStream, {
                mimeType: 'video/webm; codecs=vp8',
            });

            const chunks = [];
            recorderRef.current.ondataavailable = event => {
                if (event.data.size > 0) {
                    chunks.push(event.data);
                }
            };

            recorderRef.current.onstop = () => {
                const webmBlob = new Blob(chunks, { type: 'video/webm' });
                const url = URL.createObjectURL(webmBlob);
                setVideoUrl(url);
                setRecordedBlob(webmBlob);
                if (videoRef.current) {
                    videoRef.current.srcObject = null;
                    videoRef.current.src = url;
                    videoRef.current.muted = false;
                    videoRef.current.play()
                        .catch((err) => {
                            console.error('Error playing recorded video:', err);
                        });
                }
                setRecording(false);
            };

            recorderRef.current.start(1000);  // Start recording with shorter intervals for sync
            setRecording(true);
        } catch (err) {
            console.error('Error accessing media devices.', err);
        }
    };

    const handleDeleteRecording = () => {
        setVideoUrl('');
        setRecordedBlob(null);
        setMessage('');
        if (videoRef.current) {
            videoRef.current.srcObject = null;
            videoRef.current.src = '';
        }
        setRecording(false);
    };

    const handleUpload = async () => {
        if (!recordedBlob) {
            return;
        }
        setLoading(true);
        const URI = `${config[env].URL}/upload-video`;
        const applicationGUID = ApplicationTasks?.[selectedApplicationTask]?.applicationGUID;

        const formData = new FormData();
        formData.append('video', recordedBlob, 'recording.webm');
        formData.append('applicationGUID', applicationGUID);

        try {
            const response = await fetch(URI, {
                method: 'POST',
                body: formData,
                headers: {
                    'appkey': process.env.REACT_APP_APP_KEY,
                    'sessionid': sessionId
                }
            });
            const jsonResult = await response.json();
            const result = await apiParse(jsonResult);

            setLoading(false);
            setMessage(result.message);
            alert(result.message);

            handleDeleteRecording();
        } catch (err) {
            setLoading(false);
            console.error('Error uploading video:', err);
            setMessage(`Error uploading video: ${err.message}`);
            alert(err.message);
        }
    };

    const formatTime = (milliseconds) => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `(Maximum recording time ${minutes} minutes${seconds ? `, ${seconds} seconds.` : `.`})`;
    };

    return (
        <div>
            <h1>Camera Recorder</h1>
            <div style={{ margin: '5px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                <button 
                    className="button" 
                    onClick={recording ? handleStopRecording : handleStartRecording} 
                    disabled={!recording && !!videoUrl}
                >
                    {recording ? 'Stop Recording' : 'Start Recording'}
                </button>
                {videoUrl && (
                    <>
                        <button className="button" onClick={handleDeleteRecording}>
                            Delete Recording
                        </button>
                        <a href={videoUrl} download={`recording-${Date.now()}.webm`} style={buttonStyle}>
                            Download Recording
                        </a>
                        <button className="button" onClick={handleUpload}>
                            Upload Recording
                        </button>
                    </>
                )}
            </div>
            {message && (
                <div>
                    <h2>Message</h2>
                    <p>{message}</p>
                </div>
            )}
            {recording && (
                <div>
                    <h2>Live Preview <small><i>{formatTime(maxRecordingTime)}</i></small></h2>
                    <video 
                        ref={videoRef} 
                        autoPlay 
                        muted 
                        controls 
                        style={videoStyle} 
                    />
                </div>
            )}
            {videoUrl && !recording && (
                <div>
                    <h2>Recorded Video</h2>
                    <video 
                        ref={videoRef} 
                        src={videoUrl} 
                        autoPlay 
                        controls 
                        style={videoStyle} 
                    />
                </div>
            )}
            
            <Loader
                className="loader__spinner"
                active={loading}
                activeName="loader--active"
                loaderName="loader"
                spinnerName="loader__spinner"
            />
        </div>
    );
};

const buttonStyle = {
    display: 'inline-block',
    padding: '8px 16px',
    margin: '0 10px',
    fontSize: '1.8rem',
    fontWeight: 'bold',
    textAlign: 'center',
    textDecoration: 'none',
    color: '#fff',
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer'
};

const videoStyle = {
    width: "50%", 
    margin: "10px",
    border: "2px solid #000",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)"
};

export default CameraRecorder;
