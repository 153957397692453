import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

import { Link, useLocation, useParams, useHistory } from "react-router-dom";

// import Button from "../../Reusable/Button";

import fakedata from "./KYBSearch.json"
// import screenRibbonData from "./ScreenRibbon_220523.json";
import screenRibbonData from "./ScreenRibbon_230104.json";

// Set up grid layout
import { WidthProvider, Responsive } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

// Imports for database connectivity
import config from "../../../config";
import {
  postRequest,
  apiParse,
  apiParseScreen,
} from "../../../utils/Common.js";
import { loadPanelComponents, updateApplicationLayout, postDataViewData, getDataViewData } from "../../../actions";

import {
  selfiePanelGUID,
  certificatePanelGUID,
  onboardingCertificateGUID,
  KYBCertificateGUID,
  editLayoutGUID,
  saveLayoutGUID,
} from "../../../utils/Constants";

import deepCopy from "../../../utils/deepCopy";

// Import individual Panel component
import Panel from "../Panel";
import { confirmAlert } from "react-confirm-alert";
import ScreenRibbon from "../../Reusable/ScreenRibbon";
import GlobalSubHeader from "../../Header/GlobalSubHeader";
// import ModalForm from "../../Form/ModalForm";
import GetFormData from "../../Form/GetFormData";
import { errorHandler } from "../../../utils/errorHandler";
// import { saveScreenDefinitionToDB } from "../../../utils/DatabaseFunctions";
import GetApplicationGUID from "../../../utils/GetApplicationGUID";

import SaveSettings from "../PanelFunctions/SaveSettings";
import SaveScale from "../PanelFunctions/SaveScale";
import SaveLayout from "../PanelFunctions/SaveLayout";
import SaveGridLayout from "../PanelFunctions/SaveGridLayout";
import CheckCredit from "../PanelFunctions/CheckCredit";
import { Redirect } from "react-router-dom";


// Constants
const env = process.env.NODE_ENV || "testenv";

// const ResponsiveReactGridLayout = WidthProvider(Responsive);

const emptyObject = (data) => {
  let isEmpty = true;

  if (data && data !== "undefined" && data !== null) {
    isEmpty = Object.keys(data).length === 0 && data.constructor === Object;
  }

  return isEmpty;
};

//  START OF FUNCTIONAL COMPONENT

const DashboardPanels = (props) => {

  const [isLoading, setIsLoading] = useState(true);
  const [panelsDisplay, setPanelsDisplay] = useState([]);
  const [updatedScreenLayout, setUpdatedScreenLayout] = useState([]);
  const [layouts, setLayouts] = useState(getFromLS("panel-layout"));
  const [pdfHeight, setPdfHeight] = useState(0);
  const [scale, setScale] = useState(1.0);
  const [segmentSpotliteAddress, setSegmentSpotliteAddress] = useState(props.segmentSpotliteAddress);
  const [viewGUID, setViewGUID] = useState(props.viewGUID);
  const [screenLayout, setScreenLayout] = useState({});
  const [_applicationGUID, setApplicationGUID] = useState("");
  const [_dataFromAPI, setDataFromAPI] = useState({})

  //Also removed panelSettings, where was this used?
  const [redirect, setRedirect] = useState(false);
  const [routerPath, setRouterPath] = useState("");
  const [submittedError, setSubmittedError] = useState("");

  const [edit, setEditPanelAttributes] = useState({
    draggable: false,
    resizable: false,
    panelHandleIcon: false,
  });

  const [screen, setScreenAttributes] = useState({
    originalPanels: [],
    screenAttributes: {},
    ribbon: [],
    screenComponentGUID: '',
  });

  const [modal, setModalParams] = useState({
    modalOpen: false,
    modalScreen: "",
    serviceCode: "",
    serviceName: "",
    lobCode: "",
    lobName: "",
  });


  // const state = useSelector(state => state);
  //const location = useSelector(state => state.router.location);
  //const getView = useSelector(state => state.getView);
  //const postView = useSelector(state => state.postView);

  const { sessionId, dataConfig, portalDetails } = useSelector(state => state.session);
  const { clientCatalogueGUID, spotliteAddress, teamGUID, departmentGUID } = useSelector(state => state.session.dataConfig.allTeams.currentTeamDetails);
  const { userSettingsGUID } = useSelector(state => state.session.dataConfig);
  const { catalogues } = useSelector(state => state.credits);
  
  const ResponsiveReactGridLayout = useMemo(() => WidthProvider(Responsive), []);
  
  //console.log(state);
  //console.log(props);

  const dispatch = useDispatch();

  let _location = useLocation();
  let history = useHistory();

  useEffect(() => {

    console.log(_location);
  
    const data = {
      viewGUID: _location.state !== undefined ? _location.state.viewGUID : "",
      teamGUID,
      departmentGUID,
      userSettingsGUID,
      applicationGUID: _location.state !== undefined ? _location.state.applicationGUID : "",
    };

    // console.log(props.viewGUID);

    let applicationGUID = GetApplicationGUID(data);
    //console.log(applicationGUID)
    //setApplicationGUID(applicationGUID);
    //console.log(props.viewGUID, applicationGUID);

    try {
      // PMD 09/05/21 For and Applicant only retrieve data if a viewGUID has been set 
      if (portalDetails.portalType === 'applicant' && !applicationGUID) return

      const getData = async () => {

        //await CallDataView(callType);
        await RetrieveDataFromAPI(_location.state.spotliteAddress, applicationGUID);
        setIsLoading(false);

      }

      getData().catch(console.err);
      //setSegmentSpotliteAddress(props.segmentSpotliteAddress);

    } catch (error) {
      // PMD 20/05/22 Error handling
      errorHandler(error);
      setIsLoading(false);
      throw new Error('Error retrieving data', error);
    }
  }, [_location]);

  useEffect(() => {
    // PMD 15/01/24 React 18 upgrade return undefined 
    // if (Object.keys(_dataFromAPI).length === 0) return null
    if (Object.keys(_dataFromAPI).length === 0) return undefined

    DashBoardController(_dataFromAPI, _applicationGUID)

  }, [edit])

  //Make this one for panels, rerender the screens this way

  const sendData = (screenLayoutValues) => {
    // Send the data back to the certificates panel when the layout has changed
    setScreenLayout(screenLayoutValues);

    // console.log(this.state)
    // PMD 15/12/22 Do panel save here
    // this.props.dataSentToCertificatesPanel(screenLayoutValues);
  }

  const showAlert = (title, msg) => {
    confirmAlert({
      title: title,
      message: msg,
      buttons: [
        {
          label: "OK",
          onClick: () => { /* changes saved */ },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  const onEditClick = () => {
    console.log("Edit Clicked")
  }

  const getDataFromPanel = (panelData) => {

    setScale(panelData.scale);

    const scalePayload = {
      scale: scale,
      layout: layouts,
      updatedScreenLayout: updatedScreenLayout,
      originalPanels: screen.originalPanels,
      screenComponentGUID: screen.screenComponentGUID,
      screenAttributes: screen.screenAttributes,
      ribbon: screen.ribbon,
      sessionId: sessionId,
      teamSpotliteAddress: spotliteAddress,
      callback: updateScreenCallback,
    }

    SaveScale({ ...scalePayload })

  }

  const gridLayoutChanged = (gridLayout) => {

    // console.log(gridLayout);

    SaveGridLayout({
      gridLayout,
      updatedScreenLayout: updatedScreenLayout,
      originalPanels: screen.originalPanels,
      screenComponentGUID: screen.screenComponentGUID,
      screenAttributes: screen.screenAttributes,
      ribbon: screen.ribbon,
      callback: updateScreenCallback,
      sessionId: sessionId,
      teamSpotliteAddress: spotliteAddress,
    });

  }

  // const CallDataView = async (callType) => {

  //   //console.log("SegmentAddress", props.segmentSpotliteAddress, "TeamAddress", spotliteAddress);

  //   let options = {
  //     sessionId: sessionId,
  //     ViewGUID: viewGUID,
  //     ApplicationInstanceGUID: props.applicationInstanceGUID,
  //     ClientCatalogueGUID: clientCatalogueGUID,
  //     portalType: portalDetails.portalType,
  //     SpotliteAddress: props.segmentSpotliteAddress || "",
  //   };

  //   //Determin which call is made

  //   switch(callType) {

  //     case 'POST':

  //       dispatch(postDataViewData(getProxyData));

  //     break;

  //     case 'GET':
  //     default:

  //       dispatch(getDataViewData(options));

  //     break;

  //   }
  // }

  // Perform API calls to retrieve the data
  const RetrieveDataFromAPI = async (spotliteAddress, applicationGUID) => {

    const URI = `${config[env].URL}/get-data-view`;
    const credentials = "omit";

    let options = {
      sessionId: sessionId,
      ViewGUID: viewGUID,
      ApplicationInstanceGUID: applicationGUID,
      ClientCatalogueGUID: clientCatalogueGUID,
      portalType: portalDetails.portalType,
      SpotliteAddress: spotliteAddress || "",
    };

    // console.log("Dashboard Panels - get-data-view: ", options);

    postRequest(URI, options, credentials)
      .then(apiParseScreen)
      .then((data) => {
        if (data.ViewResponse.PropertyRef !== "200") {
          throw new Error(data);
        }

        // 04/07/23 FIXME: Hardcoded JSON for KYB Search check
        // let dataFromAPI = deepCopy(fakedata)
        // Take a copy of data so as not to mutate original data
        let dataFromAPI = deepCopy(data)
        // console.log(dataFromAPI);
        let screenRibbonDisplayed;

        // console.log("Data", dataFromAPI);
        if (dataFromAPI.Screen.ScreenRibbon === undefined) {
          // PMD 04/01/23 - Use temporary DUMMY json if screenRibbon not present
          // screenRibbonDisplayed = screenRibbonData.Screen.ScreenRibbon;
          screenRibbonDisplayed = []
        } else {
          // FIXME: PMD 24/03/23 Enable buttons for all accounts as requested by David
          // const tempScreenRibbon = dataFromAPI.Screen.ScreenRibbon
          // // if (spotliteAddress.startsWith("1.") || spotliteAddress.startsWith("2.")) {
          // tempScreenRibbon.push(screenRibbonData.Screen.ScreenRibbon[0])
          // tempScreenRibbon.push(screenRibbonData.Screen.ScreenRibbon[1])
          // // }
          // screenRibbonDisplayed = tempScreenRibbon;
          screenRibbonDisplayed = dataFromAPI.Screen.ScreenRibbon;

        }
        setScreenAttributes({
          originalPanels: dataFromAPI.Screen.Panel,
          screenAttributes: dataFromAPI.Screen.Attributes,
          ribbon: screenRibbonDisplayed,
          screenComponentGUID: dataFromAPI.Screen.Attributes.ComponentGUID,
        });

        // console.log(dataFromAPI)
        setApplicationGUID(applicationGUID)
        setDataFromAPI(dataFromAPI)
        DashBoardController(dataFromAPI, applicationGUID);
      })
      .catch((err) => {
        errorHandler(err)
        setIsLoading(false);
        setSubmittedError("Unable to load config");
      });
  };

  // Create the required number of panels based on the data provided in the returned from the api call
  const DashBoardController = (data, applicationGUID) => {

    if (!data.Screen.Panel) {
      // FIXME: ERROR HANDLING REQUIRED
      // console.info("Request for Screen data missing Panel data")
      return
    }

    // PMD 28/09/21 - Refactor code
    let panelArr = data.Screen.Panel;
    // let panelSettingsProperties = {};
    const panelsToDisplay = panelArr.map((panel) => {
      // Destructure individual panel attributes
      let activePanel = Object.values(panel)[0];

      if (activePanel.Attributes.PanelLayout === undefined || activePanel.Calls.length === 0 || !activePanel.Attributes) {
        // FIXME: ERROR HANDLING REQUIRED
        // console.info("Error loading config. Panel data not correctly formed")
        let error = {};
        error.message = "Panel data not correctly formed";
        errorHandler(error);
        // return
      }

      //console.log(activePanel);

      let panelAttributes = activePanel.Attributes;
      let panelCalls = activePanel.Calls;

      let panelGuid = panelAttributes.ComponentGUID;
      let panelType = panelAttributes.ComponentType;

      /* ******************************************************************
         Set up initial panel display structure until settings are passed
         ******************************************************************
      */
      // TODO: Handle settings and style correctly
      let panelDataGrid = { x: 0, y: 0, h: 26, w: 12, i: panelGuid, pdfHeight: 400, scale: 1.0 };
      //this.setState({ pdfHeight: panelDataGrid.pdfHeight })

      setPdfHeight(panelDataGrid.pdfHeight);

      // PMD 30/03/22 Change of location of Layout
      // if (panelSettingsProperties !== undefined) {
      if (panelAttributes !== undefined) {
        if (panelAttributes.PanelLayout !== undefined && panelAttributes.PanelLayout !== "" && panelAttributes.PanelLayout !== null) {
          // remove any tilde at the end of the string before closing }

          let formattedPanelDataGrid = panelAttributes.PanelLayout.replace("~}", "}")
          // replace ~ in the string with double quotes as required by the component
          panelDataGrid = JSON.parse(formattedPanelDataGrid.replace(/[~]/g, "\""));
          // console.info(panelDataGrid.scale)
          if (panelDataGrid.scale) {
            //this.setState({ scale: panelDataGrid.scale })
            setScale(panelDataGrid.scale)
          }
        }
      }

      let panelContent = ''
      if (panelType === 'List') {
        panelContent = activePanel;
        dispatch(loadPanelComponents({ panelGuid: panelGuid, json: panelContent }));
      } else if (panelGuid === selfiePanelGUID) {
        // Retrieve Selfie image

        const URI = `${config[env].URL}/get-selfie-data`;
        const credentials = "omit";

        let options = {
          ApplicationGUID: applicationGUID,
          sessionId: sessionId,
        };

        postRequest(URI, options, credentials)
          .then(apiParse)
          .then((data) => {
            panelContent = data;
            // console.log(data)
            // console.log(panelGuid)
            dispatch(loadPanelComponents({ panelGuid: panelGuid, json: panelContent }));
          })
          .catch((err) => {
            // PMD 20/05/22 Generic error handler
            errorHandler(err);
            setIsLoading(false);
            setSubmittedError("Unable to load config");

          });
      } else if (panelGuid === certificatePanelGUID || panelGuid === onboardingCertificateGUID || panelGuid === KYBCertificateGUID) {
        // Retrieve Certificate
        const URI = `${config[env].URL}/get-certificate-data`;
        const credentials = "omit";

        let options = {
          ApplicationGUID: applicationGUID,
          sessionId: sessionId,
        };

        //console.log(applicationGUID);

        postRequest(URI, options, credentials)
          .then(apiParse)
          .then((data) => {
            panelContent = data;
            dispatch(loadPanelComponents({ panelGuid: panelGuid, json: panelContent }));
          })
          .catch((err) => {
            // PMD 20/05/22 Generic error handler
            errorHandler(err)
            console.log(err.message);
            console.log(err);
            setIsLoading(false);
            setSubmittedError("Unable to load config");
          });
      } else {

        // PMD 04/10/21 Loop through the list of panelCalls
        // TODO: Needs testing when more than one panel call is present
        panelCalls.map(panelCall => {
          //console.info(panelCall)
          // Only get data from the RetrievedData section when the callType is CallAction
          if (panelCall.CallType === 'CallAction') {
            
            let callingObjectGUID = panelCall.CallingObjectGUID

            // PMD 28/09/21 Check for RetrievedData in new location
            const retrievedData = data.Screen.RetrievedData.find(obj => {
              if (Object.keys(obj)[0] === callingObjectGUID) {
                return obj
              }
            });

            // PMD 28/09/21 FIXME: Temporary Workaround until DashboardPanels is refactored
            let panelContentToAdd = Object.values(retrievedData)[0]
            activePanel.Calls[0].RetrievedData = panelContentToAdd
            // PMD 28/09/21 Add the information retrieved from the new RetrievedData section back into the original panel
            return panelContent = activePanel
          }

          // if(panelCall.CallType === 'CallEvent') {    
        
          //   console.log(panelCall)
          // }

        });
        dispatch(loadPanelComponents({ panelGuid: panelGuid, json: panelContent }));
      }

      // console.log(panelDataGrid, panelGuid, panelContent, panelType, panelAttributes.Label);



      return renderPanel(panelDataGrid, panelGuid, panelContent, panelType, panelAttributes.Label, applicationGUID);

    });

    // console.log(panelsToDisplay);
    setPanelsDisplay(panelsToDisplay);
    setIsLoading(false);

  };

  // Create individual panel
  const renderPanel = (layout, panelGuid, panelContent, panelType, panelTitle, applicationGUID) => {

    return (
      <div
        className={"panel"}
        key={panelGuid}
        data-grid={layout}
        style={{
          background: "white",
          // fontFamily: "ProximaNova",
          fontFamily: "Arial, Helvetica, sans-serif",
          fontSize: 13,
        }}
      >
        <Panel
          panelGuid={panelGuid}
          panelContent={panelContent}
          panelType={panelType}
          panelTitle={panelTitle}
          pdfHeight={pdfHeight}
          panelDataGrid={layout}
          dataSentToDashboardPanels={getDataFromPanel}
          // selectRow={this.selectRow}
          // showDocument={this.showDocument}
          onEditClick={onEditClick}
          segmentSpotliteAddress={segmentSpotliteAddress}
          handleAndIcon={edit.panelHandleIcon}
          gridLayoutChanged={gridLayoutChanged}
          applicationGUID={applicationGUID}
        />
      </div>
    );
  };

  const onLayoutChange = (layout, newLayout) => {

    saveLayoutToLS("panel-layout", newLayout);
    setLayouts(newLayout);

  };


  const updateCreditCallback = (item) => {

    //console.log(item);

    setModalParams({
      modalOpen: true,
      modalScreen: item.viewGUID,
      serviceName: item.Name,
      serviceCode: item.code,
      lobCode: '',
      lobName: '',
    });

  }

  const checkCredit = (item) => {
 
    // setRedirect(true);
    
    if(item.Context === 'Button') {
      //console.log(item, dataConfig.allTeams, sessionId, dataConfig, catalogues)
      handleButtonClick(item.viewGUID)
    } else if(item.Context === 'Redirect') {
      //console.log(item)
      setRouterPath(`${_location.state.pathname}/${item.RouterPath}`);
      setRedirect(true);
      dispatch(updateApplicationLayout({ componentType: "", label:`${_location.state.pathname}/${item.RouterPath}`, isVisible: true }));
    } else {
      CheckCredit({
        item,
        allTeams: dataConfig.allTeams,
        sessionId,
        CatalogueCode: dataConfig,
        catalogues,
        callback: updateCreditCallback,
      });
    }

  }

  const renderModal = () => {

    if (modal.modalOpen) {
      return (
        <GetFormData
          open={modal.modalOpen}
          closeModal={onClose}
          cancelModal={cancelModal}
          screenType={modal.modalScreen}
          ServiceName={modal.serviceName}
          ServiceCode={modal.serviceCode}
          LoBName={modal.lobName}
          LoBCode={modal.lobCode}
          applicationGUID={_applicationGUID}
        />
      );
    } else {
      return null;
    }
  };

  const launchModal = (viewGUID) => {
    setModalParams({ modalOpen: true, modalScreen: viewGUID, serviceName: '', serviceCode: '', lobCode: '', lobName: '', });
  };

  const cancelModal = () => {
    // console.log("Modal Closed by User")
    setModalParams({ modalOpen: false, modalScreen: "", serviceCode: "", serviceName: "", lobCode: "", lobName: "", });
  }

  const onClose = () => {
    // console.log("Modal Closed Automatically")
    setModalParams({ modalOpen: false, modalScreen: "", serviceCode: "", serviceName: "", lobCode: "", lobName: "", });
    RetrieveDataFromAPI(_location.state.spotliteAddress, _applicationGUID);
  };

  const handleButtonClick = (viewGUID) => {

    // console.log(viewGUID)
    // "B313A525-B62B-4F85-8DA5-2C2FF7CC1911"
    if (viewGUID === editLayoutGUID) {
      setEditPanelAttributes({
        draggable: true,
        resizable: true,
        panelHandleIcon: true,
      });
      // PMD 08/12/22 If necessary save changes to layout 
    } else if (viewGUID === saveLayoutGUID) {
      // PMD 08/12/22 Set state with changes at 107 and call savesettingstodb
      if (Object.keys(screenLayout).length > 0) {
        // this.savePanelSettingsToDB(screenLayout)
        SaveSettings({ screenLayout: screenLayout, callback: saveSettingsCallback });
      }
    } else {
      // PMD 15/12/22 Potentially no longer used
      // this.props.handleButtonClick(viewGUID)
    }
  }

  const renderRibbon = () => {



    // PMD 08/12/22 Changed to an array
    // let disableButton = ''
    const disableButton = [];

    //console.log(spotliteAddress);
    // console.log("Render Ribbon", screen.ribbon);

    // If the Edit Layout or Save Layout buttons are present and we are Business Account 1 or 2 then enable the buttons
    // Grabbing Team SpotliteAddress from Redux
    // console.log(spotliteAddress)

    // PMD 24/03/23 Buttons enabled for all accounts as requested by David
    // if (!spotliteAddress.startsWith("1.") && !spotliteAddress.startsWith("2.")) {
    //   // disableButton = editLayoutGUID
    //   disableButton.push(editLayoutGUID)
    //   disableButton.push(saveLayoutGUID)
    // }


    return (
      <div className="sub_header_content">
        <div className="sub_header_container_one">
          <ScreenRibbon screenRibbon={screen.ribbon} handleClick={launchModal} onItemClick={checkCredit} handleButtonClick={handleButtonClick} disableButton={disableButton} hidden={true} />
        </div>
      </div>
    )
  }

  const renderGrid = () => {
    // console.log(edit)
    return (
      <div className="boundToScreen">
        <ResponsiveReactGridLayout
          {...props}
          isDraggable={edit.draggable}
          isResizable={edit.resizable}
          layout={layouts}
          useCSSTransforms={true}
          style={{ position: "relative", clear: "both", background: "#F7F5F4" }}
          onLayoutChange={(layout, newLayout) => onLayoutChange(layout, newLayout)}
          onResizeStop={(layout, oldItem, newItem, placeholder, e, element) =>           
            //this.saveLayout(layout, oldItem, newItem, placeholder, e, element)
            SaveLayout({
              layout,
              scale,
              oldItem,
              newItem,
              placeholder,
              e,
              element,
              updatedScreenLayout,
              originalPanels: screen.originalPanels,
              screenComponentGUID: screen.screenComponentGUID,
              screenAttributes: screen.screenAttributes,
              ribbon: screen.ribbon,
              sessionId: sessionId,
              teamSpotliteAddress: spotliteAddress,
              callback: updateScreenCallback,
            })
          }
          onDragStop={(layout, oldItem, newItem, placeholder, e, element) =>

            SaveLayout({
              layout,
              scale,
              oldItem,
              newItem,
              placeholder,
              e,
              element,
              updatedScreenLayout,
              originalPanels: screen.originalPanels,
              screenComponentGUID: screen.screenComponentGUID,
              screenAttributes: screen.screenAttributes,
              ribbon: screen.ribbon,
              sessionId: sessionId,
              teamSpotliteAddress: spotliteAddress,
              callback: updateScreenCallback,
            })
          }
          draggableHandle=".dragHandle"
        >
          {panelsDisplay.map((panelDisplay) => {

            // check whether there is anything to display in the panel
            if (panelDisplay.props.children.props.panelContent !== null) {
              // if (panelDisplay.props.children.props.handleAndIcon !== null) {
              //   // panelDisplay.props.children.props.handleAndIcon = edit.panelHandleIcon
              // }
              return panelDisplay;
            } else {
              return null;
            }
          })}
        </ResponsiveReactGridLayout>
      </div>
    );
  };

  const updateScreenCallback = (callbackPayload) => {
    setUpdatedScreenLayout(callbackPayload.updatedScreenLayout)
    // console.log(callbackPayload)
    // PMD 29/09/21 Return new screen definition object
    sendData(callbackPayload.updatedScreenDefinition);
  }

  const saveSettingsCallback = (/*payload*/) => {
    //We don't need the payload at the moment
    showAlert('Save changes', 'Changes were successfully saved.');
    setScreenLayout({});
  }

  if (isLoading) return null;

  if (redirect) {
    
    history.push(routerPath);
    return (
        <Redirect to={
          {
            pathname: routerPath,
            state:{ viewGUID, _applicationGUID, spotliteAddress}
          }
        } />
      );
  }

  return (
    <GlobalSubHeader>
      {renderModal()}
      {renderRibbon()}
      {renderGrid()}
    </GlobalSubHeader>
  );
}

function getFromLS(layoutName) {
  if (global.localStorage) {
    let savedLayout = global.localStorage.getItem(layoutName);

    if (savedLayout && !emptyObject(savedLayout)) {
      return JSON.parse(savedLayout).layouts;
    } else {
      // defaultLayout is defined elsewhere, when nothing in LocalStorage it's how it should be.
      return {}; // defaultLayout;
    }
  }
}

// Save layout preference to localStorage
function saveLayoutToLS(layoutName, value) {
  if (global.localStorage) {
    global.localStorage.setItem(layoutName, JSON.stringify({ layouts: value }));
  } else {
    console.error("localStorage is not supported");
  }
}

export default DashboardPanels;
